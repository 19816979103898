import React, {useContext, useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import { userdetails } from "../context/userdetailsContext";
import Sidebar from "../components/sidebar";
import Header from "../components/header";
import apiTasks from '../api';
import urlConstants from "../api/urls";
import Multiuser from "../components/multiuser";
import Pagespinner from "../components/pagespinner";
import CalendarHeatmap from "react-calendar-heatmap";

function Sessionhistory() {
    let navigate = useNavigate();
    const { isLoading, setIsLoading, historydetails,
        sethistorydetails} = useContext(userdetails);
    
    const localusercheck = JSON.parse(localStorage.getItem('localuser'));
    if (!localusercheck || (localusercheck &&  (!localusercheck.isAuthenticated || !localusercheck.user))) {
       navigate("/login");
    }
    function msToTime(ms) {
        let seconds = (ms / 1000).toFixed(1);
        let minutes = (ms / (1000 * 60)).toFixed(1);
        let hours = (ms / (1000 * 60 * 60)).toFixed(1);
        let days = (ms / (1000 * 60 * 60 * 24)).toFixed(1);
        if (seconds < 60) return seconds + " Sec";
        else if (minutes < 60) return minutes + " Min";
        else if (hours < 24) return hours + " Hrs";
        else return days + " Days"
      }
      
      

    useEffect(() => {
        const localusercheck = JSON.parse(localStorage.getItem('localuser'));
        if (localusercheck.user) {
            const newpayload = { "collection_name": "sessions", "filter": {"is_live" : false}, "field_selection_filter" : {"_id" : 0, "focus_score": 0, "active_score":0}, "record_limit" : "all", "return_type" : 2, "ret_dict_key" : "session_id"}
        const urlnew = `${urlConstants.baseUrl}${urlConstants.generalGet}`;
        apiTasks.post(urlnew, newpayload).then(res => {
            let temparray = [];
            Object.keys(res).forEach(key => {
                temparray.push(res[key]);
            });
            sethistorydetails(temparray);
            setIsLoading(false);
        });
        }
    },[])
    const today = new Date();
    const randomValues = getRange(200).map(index => {
        return {
          date: shiftDate(today, -index),
          count: getRandomInt(1, 3)
        };
      });
      function shiftDate(date, numDays) {
        const newDate = new Date(date);
        newDate.setDate(newDate.getDate() + numDays);
        return newDate;
      }
      
      function getRange(count) {
        return Array.from({ length: count }, (_, i) => i);
      }
      
      function getRandomInt(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
      }
      
    return (<div className="mx-auto" style={{width: "1320px"}}>
        <div className="row">
        {isLoading ?
                <Pagespinner /> : null}
            <div className="col col-3">
                <Sidebar selected="shistory" userdetails={localusercheck.user} />
            </div>
            <div className="col col-9">
                <div className="rightcontainer">
                    <Header userdetails={localusercheck.user} />
                    <h2 className="page-title"><i className="bi bi-clock"></i> Sessions history</h2>
                    
                    {/*<div className="row mb-3 mt-5">
                        <div className="col col-4">
                            <div className="mb-3">
                                <input type="text" className="form-control" id="exampleFormControlInput2" placeholder="Search" />
                            </div>
                        </div>
                    </div>
                    
                     <div style={{ maxHeight: "500px", overflow: "scroll", margin: "20px"}}>
                    <table className="table">
                        <thead style={{position: "sticky", backgroundColor: "#fff", top: "0px"}}>
                            <tr>
                                <th scope="col">S.no</th>
                                <th scope="col">Session name</th>
                                <th scope="col">Date</th>
                                <th scope="col">Duration</th>
                                <th scope="col">Score</th>
                                <th scope="col">Rank</th>
                                <th scope="col">Friends</th>
                            </tr>
                        </thead>
                        <tbody>
                            {historydetails && historydetails.length > 0 && historydetails.map((item,idx) => (<tr>
                                <th scope="row">{idx+1}</th>
                                <td>{item.session_id}</td>
                                <td>{item.start_time}</td>
                                <td>{msToTime(item.current_duration)}</td>
                                <td className="green">{item.score}</td>
                                <td>1</td>
                                <td><Multiuser /></td>
                            </tr>))}
                        </tbody>
                    </table>
                </div> */}
                <div>
                        <CalendarHeatmap
                            startDate={shiftDate(today, -30)}
                            endDate={today}
                            values={randomValues}
                            classForValue={value => {
                            if (!value) {
                                return "color-empty";
                            }
                            return `color-github-${value.count}`;
                            }}
                            gutterSize={1}
                            showWeekdayLabels={true}
                            onClick={value => alert(`Clicked on value with count: ${value.count}`)}
                            transformDayElement={(element, value, index) => (
                            <g key={index}>
                                {element}
                                <text
                                x={element["props"].x + 4.5}
                                y={element["props"].y + 6}
                                style={{
                                    fontSize: ".1em",
                                    fill: "#fff"
                                }}
                                >
                                {value.date.getDate()}
                                </text>
                            </g>
                            )}
                        />
                    </div>
                </div>
            </div>
        </div>

    </div>);
}
export default Sessionhistory;