import React, { Component } from 'react';
import Chart from 'react-apexcharts'


function DonutChart(props) {

    const percent = props.percent ? props.percent : 0;
    const timeleft = (Number(props.target_duration)-Number(props.current_duration)) > -1 ? (Number(props.target_duration)-Number(props.current_duration)) : 0;
    const donutoptions = {
          
      series: [percent],
      options: {
        chart: {
          height: 180,
          type: 'radialBar'
        },
        plotOptions: {
          radialBar: {
            startAngle: -145,
            endAngle: 150,
             hollow: {
              margin: 0,
              size: '70%',
              background: '#fff',
              image: undefined,
              imageOffsetX: 0,
              imageOffsetY: 0,
              position: 'front',
              
            },
            track: {
              background: '#EBEBEB',
              strokeWidth: '80%',
              margin: 0,
              
            },
        
            dataLabels: {
              show: true,
              name: {
                show: true,
                offsetY: 15,
                color: '#888',
                fontSize: '11px'
              },
              value: {
                formatter: function(val) {
                  return '';
                },
                offsetY: -15,
                color: '#111',
                fontSize: '22px',
                show: true,
              }
            }
          }
        },
        fill: {
          colors: ["#1757D7"]
        },
        stroke: {
          lineCap: 'round'
        },
        labels: ['Minutes Left'],
      },
    };
  return (
    <div className="donut">
      <span className='timesleft' style={{ left: (Number(props.target_duration) - Number(props.current_duration)).length === 2 ? '72px' : '80px' }}>{Number(props.current_duration) ? timeleft:''}</span>
      <span className='timestotal' style={{ position: 'absolute'}}>{Number(props.target_duration)} m</span>
      <Chart options={donutoptions.options} series={donutoptions.series} type="radialBar" width="180" />
    </div>
  );
}

export default DonutChart;