import { apiProvider } from './provider';

export class ApiCore {
    constructor(options) {
        if (options.get) {
            this.get = (url) => {
                return apiProvider.get(url);
            }
        }
        if (options.post) {
            this.post = (url, model) => {
                return apiProvider.post(url, model);
            }
        }
        if (options.put) {
            this.put = (url, model) => {
                return apiProvider.put(url, model);
            }
        }
    }
}