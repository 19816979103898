
import './Login.css';
import loginbgimg from '../assets/loginbg.png';
import logo from '../assets/logo.png';
//import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
function Login() {
    const { loginWithRedirect } = useAuth0();
    //let navigate = useNavigate();
    function handleClick() {
        loginWithRedirect()
        //navigate("/home");
    }
    return (<div className="container-fluid login">
        <div className="row">
            <div className="col pt-2 text-center">
                <div className="loginbgcontainer" style={{ minHeight: "97vh" }}>
                    <img src={loginbgimg} alt="background" style={{ marginTop: "15%" }} />
                </div>
            </div>
            <div className="col jus" style={{ marginTop: "12%" }}>
                <div className="row">
                    <div className="col-2"></div>
                    <div className="col-7">
                        <div className="logincontainer" >
                            <img src={logo} alt="Logo" style={{ verticalAlign: "middle" }} />
                            <h2 className="mt-3 mb-3">Productive fish</h2>
                            <form>
                                {/* <div className="mb-3">
                                    <label htmlFor="exampleInputEmail1" className="form-label">Email / User name *  </label>
                                    <input type="email" className="form-control" id="exampleInputEmail1" />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="exampleInputPassword1" className="form-label">Password  *</label>
                                    <input type="password" className="form-control" id="exampleInputPassword1" />
                                </div> */}
                                <div className="d-grid gap-2 ">
                                    <button type="button" onClick={handleClick} className="btn btn-primary mt-3 mb-3">Login / Register</button>
                                    <button type="button" className="btn btn-link">Forgot password</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>)
}
export default Login;