export const startlocalagent = (url, params) => {
    const http = new XMLHttpRequest();
    http.open('POST', url, true);
    http.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
    http.onreadystatechange = function() {
        if (http.readyState == 4 && http.status == 200) {
            //console.log(http.responseText);
        }
    }
    http.send(params);
};

export const generateOptionValue = (arr) => {
    const tempval = arr && arr.length > 0 && arr.map((item, val) => (<option key={val} value={item}>{item}</option>))
    return tempval;
}
export const getScreenWidthHeight = () => {
    var win = window,
    doc = document,
    docElem = doc.documentElement,
    body = doc.getElementsByTagName('body')[0],
    x = win.innerWidth || docElem.clientWidth || body.clientWidth,
    y = win.innerHeight|| docElem.clientHeight|| body.clientHeight;

    const body01 = document.body;
    const html = document.documentElement;

    const height = Math.max(body01.scrollHeight, body01.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);

    return {
        width: x,
        height: height
    }
}