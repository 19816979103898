import React from "react";
import smallprofile from '../assets/smallimage.png';
import './header.css';
function Leaderboard({ leaderboard }) {
    return (<div className="leaderboard">
        <ul className="list-group">
            {
                leaderboard && leaderboard.map((friends, idx) => {
                    return (<li className="list-group-item" key={idx}>
                        <div className="row">
                            <div className="col-1 pt-2">{idx + 1}</div>
                            <div className="col-2">{friends.smallprofile ?
                                <p className="userImageHolderimg"><img src={smallprofile} alt="profile_image" className="logo" /> {friends.is_live ? <span className="online"></span>: null }</p> :
                                <p className="userImageHolder">{friends.real_name.toUpperCase().charAt(1)}{friends.is_live ?<span className="online"></span>:null}</p>}
                            </div>
                            <div className="col-5" style={{ paddingRight: 0}}>
                                <div className="uname">{friends.real_name}</div>
                                <div style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>{friends.alias_name}</div>
                            </div>
                            <div className="col-2" ><div className="status" >{friends.is_live ?

                                <span className={"badge " } style={{ background: "#d7f2d6",
                                color: "#419c3e"}}>{/* <i className="bi bi-triangle-fill"></i> */} Live</span>
                                :
                                <span className="badge bg-light left">Left</span>}</div>
                            </div>
                            <div className="col-2" style={{ paddingRight: 0}}><p className={friends.score > 0 ? "green" : "red"}>{Math.abs(friends.score)}</p></div>
                        </div>
                    </li>)
                })
            }
        </ul>
    </div>
    )
}
export default Leaderboard;