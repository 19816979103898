import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { userdetails } from "../context/userdetailsContext";
import Sidebar from "../components/sidebar";
import Header from "../components/header";
import Pagespinner from "../components/pagespinner";
import smallprofile from '../assets/smallimage.png';
import apiTasks from '../api';
import urlConstants from "../api/urls";
function Manageusers() {
    const { isLoading, setIsLoading} = useContext(userdetails);
    const [newfriend, setNewfriend] = useState("");

    const [friendrequested, setfriendrequested] = useState([]);
    const [friendconfirm, setfriendconfirm] = useState([]);
    const [friendlist, setfriendlist] = useState([]);

    let navigate = useNavigate();
    const localusercheck = JSON.parse(localStorage.getItem('localuser'));
    if (!localusercheck || (localusercheck &&  (!localusercheck.isAuthenticated || !localusercheck.user))) {
       navigate("/login");
    }
    useEffect(() => {
        getfriendslist();
    },[]);
    const getfriendslist = () => {
        const newpayload = { "collection_name": "users", "filter": { "user_id" : localusercheck.user.name }, "field_selection_filter" : { "_id" : 0, "auth_tokens": 0}, "record_limit" : "all", "return_type" : 2, "ret_dict_key" : "user_id"};
        const urlnew = `${urlConstants.baseUrl}${urlConstants.generalGet}`;
        setIsLoading(true);
        apiTasks.post(urlnew, newpayload).then(res => {
            const userdata = res[localusercheck.user.name];
            if (userdata) {
                setfriendrequested(userdata.friend_requested);
                setfriendconfirm(userdata.friend_confirm);
                setfriendlist(userdata.friends);
            }
            
            setIsLoading(false);
        });
    }
    const blockusers = (rfriendid) => {
        const newpayload = {"user_id": localusercheck.user.name, "blocked_user": rfriendid};
        const urlnew = `${urlConstants.baseUrl}${urlConstants.blockfriend}`;
        setIsLoading(true);
        apiTasks.post(urlnew, newpayload).then(res => {
            getfriendslist();
            setIsLoading(false);
        });
    }
    const unblockusers = (rfriendid) => {
        const newpayload = {"user_id": localusercheck.user.name, "unblocked_user": rfriendid};
        const urlnew = `${urlConstants.baseUrl}${urlConstants.unblockfriend}`;
        setIsLoading(true);
        apiTasks.post(urlnew, newpayload).then(res => {
            getfriendslist();
            setIsLoading(false);
        });
    }
    const acceptusers = (rfriendid) => {
        const newpayload = {"user_id": localusercheck.user.name, "friend_accepted": rfriendid};
        const urlnew = `${urlConstants.baseUrl}${urlConstants.acceptfriend}`;
        setIsLoading(true);
        apiTasks.post(urlnew, newpayload).then(res => {
            getfriendslist();
            setIsLoading(false);
        });
    }
    const rejectusers = (rfriendid) => {
        const newpayload = {"user_id": localusercheck.user.name, "friend_rejected": rfriendid};
        const urlnew = `${urlConstants.baseUrl}${urlConstants.rejectfriend}`;
        setIsLoading(true);
        apiTasks.post(urlnew, newpayload).then(res => {
            getfriendslist();
            setIsLoading(false);
        });
    }
    const requestfriend = () => {
        if(newfriend && newfriend.length > 3) { 
            const newpayload = {"user_id": localusercheck.user.name, "friend_requested": newfriend};
            const urlnew = `${urlConstants.baseUrl}${urlConstants.addfriend}`;
            setIsLoading(true);
            apiTasks.post(urlnew, newpayload).then(res => {
                setNewfriend("");
                getfriendslist();
                setIsLoading(false);
            });
        }
    }
    const onchangenewfriend = (e) => {
        setNewfriend(e.target.value);
    }
    return (<div className="mx-auto" style={{width: "1320px"}}>
        <div className="row">
            {isLoading ?
                <Pagespinner /> : null}
            <div className="col col-3">
                <Sidebar selected="muser" userdetails={localusercheck.user} />
            </div>
            <div className="col col-9">
                <div className="rightcontainer">
                    <Header userdetails={localusercheck.user} />
                    <h2 className="page-title">Friends</h2>
                    <div className="row">
                        <div className="col">
                            <div className="card panelps">
                                <div className="card-body">
                                    <div className="">
                                        <form className="row g-3">
                                            <div className="col-3 align-self-center" style={{textAlign: "right"}}>
                                                <label htmlFor="inputwhitelist" className="">Add a friend : </label>
                                            </div>
                                            <div className="col align-self-center">
                                                <input type="text" className="form-control" id="newfriend" placeholder="Friend id" value={newfriend} onChange={(e) => onchangenewfriend(e)} />
                                            </div>
                                            <div className="col-3 align-self-center">
                                                <button type="button" className="btn btn-primary" onClick={() => requestfriend()}>Add New Friend</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                    <div className="col col-4">
                        <div className="card panelps">
                            <div className="card-body">
                                <h5 className="card-title"><i className="bi bi-person"></i> Accept/Reject </h5>
                                <div className="leaderboard">
                                    <ul className="list-group">
                                        {friendrequested && friendrequested.length>0 && friendrequested.map((friend, idx)=>(
                                            <li className="list-group-item" key={idx}>
                                                <div className="row">
                                                    <div className="col-1 pt-2">1</div>
                                                    <div className="col-2">
                                                        <p className="userImageHolderimg"><img src={smallprofile} alt="profile_image" className="logo" /><span className="online"></span></p>
                                                    </div>
                                                    <div className="col-7">
                                                        <div className="uname">{friend}</div>
                                                        <div><button className="btn btn-light green buttonStyle01" onClick={() => acceptusers(friend)}>Accept</button> <button className="btn btn-light red buttonStyle01" onClick={() => rejectusers(friend)}>Reject</button></div>
                                                    </div>


                                                </div>
                                            </li>
                                        ))}
                                        {!friendrequested || friendrequested.length==0 ? (<li className="list-group-item">No Records found</li>): null}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col col-4">
                        <div className="card panelps">
                            <div className="card-body">
                                <h5 className="card-title"><i className="bi bi-person"></i> Request sent</h5>
                                <div className="leaderboard">
                                    <ul className="list-group">
                                        {friendconfirm && friendconfirm.length>0 && friendconfirm.map((friend, idx)=>(
                                            <li className="list-group-item" key={idx}>
                                                <div className="row">
                                                    <div className="col-1 pt-2">1</div>
                                                    <div className="col-2">
                                                        <p className="userImageHolderimg"><img src={smallprofile} alt="profile_image" className="logo" /><span className="online"></span></p>
                                                    </div>
                                                    <div className="col-7">
                                                        <div className="uname">{friend}</div>
                                                        <div><button className="btn btn-light red buttonStyle01" onClick={() => blockusers(friend)}>Block</button> </div>
                                                    </div>
                                                </div>
                                            </li>
                                        ))}
                                        {!friendconfirm || friendconfirm.length==0 ? (<li className="list-group-item">No Records found</li>): null}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col col-4">
                        <div className="card panelps">
                            <div className="card-body">
                                <h5 className="card-title"><i className="bi bi-person"></i> Friends </h5>
                                <div className="leaderboard">
                                    <ul className="list-group">
                                        {friendlist && friendlist.length>0 && friendlist.map((friend, idx)=>(
                                            <li className="list-group-item" key={idx}>
                                                <div className="row">
                                                    <div className="col-1 pt-2">1</div>
                                                    <div className="col-2">
                                                        <p className="userImageHolderimg"><img src={smallprofile} alt="profile_image" className="logo" /><span className="online"></span></p>
                                                    </div>
                                                    <div className="col-7">
                                                        <div className="uname">Satheesh kumar </div>
                                                        <div><button className=" btn btn-light red buttonStyle01" onClick={() => blockusers(friend)}>Block</button></div>
                                                    </div>
                                                </div>
                                            </li>
                                        ))}
                                        {!friendlist || friendlist.length == 0 ? (<li className="list-group-item">No Records found</li>): null}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>

    </div>);
}
export default Manageusers;