import React from "react";
import Notification from "./notification";
import './header.css';
function Header(props) {
    return (<header>
        <div className="row">
            <div className="col"><div className="welcometext">☀️  <span>Welcome back {props.userdetails && props.userdetails.nickname}!</span></div></div>
            <div className="col text-right" align="right">
                <Notification />
            </div>
        </div>
    </header>);
}
export default Header;