import axios from "axios";
import { handleResponse, handleError } from "./response";


const get = (url) => {
    return axios.get(url).then(handleResponse).catch(handleError);
}

const post = (url, model) => {
    return axios.post(url, model).then(handleResponse).catch(handleError);
}

const put = (url, model) => {
    return axios.get(url, model).then(handleResponse).catch(handleError);
}

export const apiProvider = {
    get,
    post,
    put
}