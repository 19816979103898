import axios from 'axios';

// Loads global configuration from S3 and sets the base URL for API requests.
// This ensures the application uses the most up-to-date API endpoint.
const loadS3GlobalConfigJson = async (s3GlobalConfigJsonUrl) => {
  console.log(`s3_global_config_json_url: ${s3GlobalConfigJsonUrl}`);
  try {
    const response = await axios.get(s3GlobalConfigJsonUrl, { timeout: 10000 });
    const config = response.data;
    console.log("Successfully loaded S3 global config:");
    console.log(JSON.stringify(config, null, 2));
    return config;
  } catch (error) {
    console.error(`Error accessing S3 global config: ${error}`);
    return null;
  }
};

const s3GlobalConfigJsonUrl = 'https://productive-fish-installer.s3.amazonaws.com/config/global_config.json';
let baseUrl = ''; // Default value

const urlConfig = {
  baseUrl: '',
  joinagent: 'https://127.0.0.1:7777/join_session',
  leaveagent: 'https://127.0.0.1:7777/stop_session',
  login: '/login',
  getSession: '/get_session',
  getArenaSummary: '/get_arena_summary',
  joinSession: '/join_session',
  leaveSession: '/leave_session',
  generalGet: '/get',
  generalPut: '/put',
  acceptfriend: '/friends/accept_friend',
  rejectfriend: '/friends/reject_friend',
  blockfriend: '/friends/block',
  unblockfriend: '/friends/unblock',
  addfriend: '/friends/make_friend_request'
};

loadS3GlobalConfigJson(s3GlobalConfigJsonUrl)
  .then(config => {
    if (config && config.base_url) {
      urlConfig.baseUrl = config.base_url;
      console.log(`Base URL updated: ${urlConfig.baseUrl}`);
    }
  })
  .catch(error => {
    console.error('Failed to load S3 global config:', error);
  });

export default urlConfig;
