import React, { Component } from 'react';
import Chart from 'react-apexcharts'

function AreaChart(props) {
    let scorelocal = props.score;
    if(props.score && props.score.length > 30) {
        scorelocal = props.score.slice(-30);
    }
    const areaoptions = {
        series: [{
            name: 'Score',
            data: scorelocal ? scorelocal : []
        }],
        options: {
            chart: {
                height: 350,
                type: 'area',
                toolbar: {
                    show: false
                  }
            },
            dataLabels: {
                enabled: false
            },
            fill: {
                type: "solid",
                /* gradient: {
                  shadeIntensity: 1,
                  gradientToColors: ['#ED848F', '#462EDF'],
                  opacityFrom: 0.7,
                  opacityTo: 0.9,
                  stops: [0, 90, 100]
                } */
              },
            stroke: {
                curve: 'smooth',
            },
            dropShadow: {
                enabled: true,
                top: 0,
                left: 0,
                blur: 3,
                opacity: 0.5
              },
            xaxis: {
                type: 'category',
                categories: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16,17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60]
            },
            tooltip: {
                x: {
                    format: 'dd/MM/yy HH:mm'
                },
            },
        },
    };
    return (
        <div className="area">
            <Chart options={areaoptions.options} series={areaoptions.series} type="area" width="570" />
        </div>
    );
}

export default AreaChart;
