import React from "react";
import './header.css';
function Notification() {
    const navitensval = [{
        id: 1,
        status: "unread",
        time: "3 hrs ago",
        message: "Notification message goes here."
    },
    {
        id: 2,
        status: "normal",
        time: "1 day ago",
        message: "Notification message goes here."
    }, {
        id: 3,
        status: "normal",
        time: "1 day ago",
        message: "Notification message goes here."
    }]
    return (<div className="dropdown ">
        <button type="button" className="btn btn-light btn-notify br-10" data-bs-toggle="dropdown" aria-expanded="false"><i className="bi bi-bell"></i><span className="notify">&nbsp;</span></button>
        <div className="dropdown-menu notification shadow">
            <h6><div className="row"><div className="col text-left">Notification</div> <div className="col" style={{ textAlign: "right" }}><button type="button" className="btn btn-outline-dark">Clear</button></div></div></h6>
            <ul className="list-group">
                {navitensval.map((item) => <li className="list-group-item" key={item.id}>
                    <div className="row">
                        <div className="col col-2">
                            <button type="button" className={"btn btn-light btn-icon br-10 " + item.status}><i className="bi bi-bell"></i></button>
                        </div>
                        <div className="col col-9">
                            <div className="hours">{item.time}</div>
                            <div>{item.message}</div>
                        </div>
                    </div>

                </li>)}
            </ul>
        </div>
    </div>);
}
export default Notification;