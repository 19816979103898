import React, { useContext, useEffect, useState } from "react";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import apiTasks from '../api';
import urlConstants from "../api/urls";
import loginbgimg from '../assets/loginbg.png';
import { useAuth0 } from "@auth0/auth0-react";
import Sidebar from "../components/sidebar";
import Header from "../components/header";
import Pagespinner from "../components/pagespinner";
import { userdetails } from "../context/userdetailsContext";
import { generateOptionValue } from "../utilities";

function Home() {

    const { user, isAuthenticated, isLoading } = useAuth0();
    const [createjoin, setCreatejoin] = useState("create");
    const [selectedhr, setSelectedhr] = useState();
    const [selectedmin, setSelectedmin] = useState();
    const {
        loggedinUser,
        setLoggedinUser,
        setIsAuthenticated,
        ISLoading,
        setIsLoading,
        setAuthToken,
        sessionlist,
        livesession,
        setLivesession,
        sessionDetails,
        setSessionlist,
        setSessionDetails
    } = useContext(userdetails);
    const hrs = ["1","2","3","4","5","6","7","8","9","10","11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24"];
    const mins = ["5", "10", "15", "20", "25", "30", "35", "40", "45", "50", "55"];
    let navigate = useNavigate();
    const todaysessionlist = [];
    const hourslist = [
        "1AM","2AM","3AM","4AM","5AM","6AM","7AM","8AM","9AM","10AM","11AM", "12PM", "1PM", "2PM", "3PM", "4PM", "5PM", "6PM", "7PM", "8PM", "9PM", "10PM", "11PM", "12AM"
    ];
    const localusercheck = JSON.parse(localStorage.getItem('localuser'));
    const localactivesession = JSON.parse(localStorage.getItem("localactivesession"));
    if (!localusercheck || (localusercheck && !localusercheck.isAuthenticated)) {
        if (!isAuthenticated || !user) {
            localStorage.removeItem("localactivesession");
            navigate("/login");
        }
    } else if(localStorage.getItem('localuser') && localusercheck && localusercheck.isAuthenticated && livesession && livesession.is_live) {
        navigate("/activesession");
    } else if(localStorage.getItem('localuser') && localusercheck){
        const localusercheck = JSON.parse(localStorage.getItem('localuser'));
        if (localusercheck && localusercheck.user) {
            const newpayload = { "collection_name": "sessions", "filter": {"is_live" : false}, "field_selection_filter" : {"_id" : 0, "focus_score": 0, "active_score":0}, "record_limit" : "all", "return_type" : 2, "ret_dict_key" : "session_id"}
        const urlnew = `${urlConstants.baseUrl}${urlConstants.generalGet}`;
        apiTasks.post(urlnew, newpayload).then(res => {
            let temparray = [];
            Object.keys(res).forEach(key => {
                temparray.push(res[key]);
            });
            
            //console.log(temparray);
            var today = new Date();
            var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0');
            var yyyy = today.getFullYear();
            
            today = dd + '/' + mm + '/' + yyyy;
            let filtered_array = _.filter(
                temparray, function(o) {
                   return o && o.start_time && o.start_time.includes(today);
                }
            );
            localStorage.setItem('localprevsessions', JSON.stringify(filtered_array));
            setIsLoading(false);
        });
        }
    }
    useEffect(() => {
        if ((localusercheck && !localusercheck.user) || user) {
            setLoggedinUser(user);
            setIsAuthenticated(isAuthenticated);
            setIsLoading(isLoading);
            const localuser = {
                isLoading,
                isAuthenticated,
                user
            }
            localStorage.removeItem("localactivesession");
            localStorage.setItem('localuser', JSON.stringify(localuser));
        } else if (localusercheck && localusercheck.user && !user) {
            setLoggedinUser(localusercheck.user);
            setIsAuthenticated(localusercheck.isAuthenticated);
            setIsLoading(localusercheck.isLoading);
        }
    }, [user]);
    
    useEffect(() => {
        const localactivesession = JSON.parse(localStorage.getItem("localactivesession"));
        if (loggedinUser.name && (!livesession || livesession && livesession.session_id !== "0" && !livesession.is_live)) {
            const url = `${urlConstants.baseUrl}${urlConstants.login}`;
            setIsLoading(true);
            apiTasks.post(url, { "user_id": loggedinUser.name, "pswd": "password" }).then((res) => {
                if (res.status === "success") {
                    setSessionDetails(res);
                    setAuthToken(res.auth_token);
                    apiTasks.post(`${urlConstants.baseUrl}${urlConstants.getSession}`, { "user_id": loggedinUser.name }).then((sessionres) => {
                        if (sessionres && sessionres.is_live && sessionres.session_id !== "0") {
                            setLivesession({});
                            setLivesession(sessionres);
                            const localactivesession = sessionres
                            localStorage.setItem('localactivesession', JSON.stringify(localactivesession));
                            navigate("/activesession");
                        } else {
                            setIsLoading(false);
                        }
                    });
                }
            })
        }
    }, [loggedinUser]);
    /* useEffect(() => {
        const localusercheck = JSON.parse(localStorage.getItem('localuser'));
        if (localusercheck && localusercheck.user) {
            const newpayload = { "collection_name": "sessions", "filter": {"is_live" : false}, "field_selection_filter" : {"_id" : 0, "focus_score": 0, "active_score":0}, "record_limit" : "all", "return_type" : 2, "ret_dict_key" : "session_id"}
        const urlnew = `${urlConstants.baseUrl}${urlConstants.generalGet}`;
        apiTasks.post(urlnew, newpayload).then(res => {
            let temparray = [];
            Object.keys(res).forEach(key => {
                temparray.push(res[key]);
            });
            
            //console.log(temparray);
            var today = new Date();
            var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0');
            var yyyy = today.getFullYear();
            
            today = dd + '/' + mm + '/' + yyyy;
            let filtered_array = _.filter(
                temparray, function(o) {
                   return o.start_time && o.start_time.includes(today);
                }
            );
            localStorage.setItem('localprevsessions', JSON.stringify(filtered_array));
            setIsLoading(false);
        });
        }
    },[]); */
    const prevsessionstoday = JSON.parse(localStorage.getItem("localprevsessions"));
    useEffect(() => {
        if (sessionDetails.session && sessionDetails.session !== '' && sessionDetails.session.length !== 0 && sessionDetails.session !== 'None') {
            const session = String(sessionDetails.session).replaceAll('ObjectId(', '');
            const sdetails01 = session.replaceAll(')', '');
            const sdetails02 = sdetails01.replaceAll("'", '"');
            const sdetails03 = sdetails02.replaceAll("True", 'true');
            const sdetails04 = sdetails03.replaceAll("False", 'false');
            const result = Array.isArray(JSON.parse(sdetails04));
            if (!result) {
                setSessionlist([JSON.parse(sdetails04)]);
            } else {
                setSessionlist(JSON.parse(sdetails04));
            }
        }
    }, [JSON.stringify(sessionDetails.session)]);
    
    const joinsession = () => {
        if (selectedhr !== 'Hrs' || selectedmin !== 'Mins') {
            let tempselectedhr = 0;
            let tempselectedmin = 0;
            if (selectedhr && selectedhr !== 'Hrs') {
                tempselectedhr = Number(selectedhr) * 60;
            }
            if (selectedmin && selectedmin !== 'Mins') {
                tempselectedmin = Number(selectedmin);
            }
            const duration = tempselectedhr + tempselectedmin;
            setIsLoading(true);

            const timezone_string = Intl.DateTimeFormat().resolvedOptions().timeZone
            const url = `${urlConstants.baseUrl}${urlConstants.joinSession}`;
            
            console.log("Joining session...");
            console.log("Base URL:", urlConstants.baseUrl);
            console.log("Join Session endpoint:", urlConstants.joinSession);
            console.log("Full URL:", url);

            const payload = { "user_id": localusercheck.user.name, "target_duration": duration, "client_tz": timezone_string };
            apiTasks.post(url, payload).then(ress => {
                setLivesession(ress);
                if (ress.session_id && ress.session_id !== '0') {
                    const localactivesession = {...ress, is_live: true};
                    console.log(localactivesession);
                    localStorage.setItem('localactivesession', JSON.stringify(localactivesession));
                    navigate("/activesession");
                }
            }).catch(() => { });
        }
    }

    const selecthrchange = (e) => {
        setSelectedhr(e.target.value);
    }
    
    const selectminchange = (e) => {
        setSelectedmin(e.target.value);
    }
    const prevsessionClick = (prevsessions) => {
        setLivesession(prevsessions);
        console.log(prevsessions);
        //const prevsessionval = {"status":"success","arena_id":"Sunday_July_10_2022","session_id":"625798fabb69a6f92bf9d6d17b6ce2","is_live":true};
        localStorage.setItem('localactivesession', JSON.stringify(prevsessions));
        navigate("/activesession");
    }
    const returntime = (dateval) => {
        return dateval.slice(10, 20);
    }
    return (<div className="mx-auto" style={{width: "1320px"}}>
        <div className="row">
            {ISLoading ?
                <><Pagespinner /></> : null}
            <div className="col col-3">
                <Sidebar selected="asession" userdetails={localusercheck && localusercheck.user} />
            </div>
            <div className="col col-9">
                <div className="rightcontainer">

                    <Header userdetails={localusercheck && localusercheck.user} />
                    <h2 className="page-title"><i className="bi bi-lightning"></i> Home </h2>
                    

                    <div className="btnbar">
                    {prevsessionstoday && prevsessionstoday.length >0 && prevsessionstoday.map((val, idx) => ( <button type="button" className="btn btn-secondary prevsession" onClick={()=>prevsessionClick(val)} key={idx}> {val && val.start_time && returntime(val.start_time)}</button> ))}
                        {
                            sessionlist && sessionlist.length > 0 && sessionlist.map((sess, sesskey) => {
                                return (<button type="button" key={sesskey} className="btn btn-primary prevsession">Active Session</button>)
                            })
                        }
                        
                        {/* <button type="button" className="btn btn-secondary"><i className="bi bi-plus-lg"></i> New Session</button> */}
                    </div>
                    <div className="hourslist" style={{position: "relative"}}>
                        {/* <button type="button" className="btn btn-primary hourbutton" style={{ left: `${22 * 38}px`}}>Active Session</button> */}
                        <div style={{ display: "contents"}}>{hourslist && hourslist.map((hr, hridx) => (<span key={hridx} className="hour">{hr}</span>))}</div>
                    </div>
                    <div className="card panelps homecardcontainer">
                        <div className="row">
                            <div className="col col-5 loginbgimgcontainer">
                                <div className="loginbgimgholder">
                                    <img src={loginbgimg} alt="background" width="275" height="275" />
                                </div>
                            </div>
                            <div className="col col-7">

                                {/* <div className="createsessionrightcontainer">
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" onChange={(e) => { setCreatejoin("create"); }} type="radio" checked={createjoin === "create"} name="inlineRadioOptions" id="inlineRadio1" value="create" />
                                        <label className="form-check-label" htmlFor="inlineRadio1">New Session</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" onChange={(e) => { setCreatejoin("join"); }} type="radio" checked={createjoin === "join"} name="inlineRadioOptions" id="inlineRadio2" value="join" />
                                        <label className="form-check-label" htmlFor="inlineRadio2">Join Session</label>
                                    </div>
                                </div> */}
                                {/* {createjoin === "create" ?
                                    <div className="createsession">
                                        <form>
                                            <div className="mb-3">
                                                <label htmlFor="exampleInputEmail1" className="form-label">Session Name  </label>
                                                <input type="text" className="form-control" id="exampleInputEmail1" placeholder="Session Name" />
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="exampleInputPassword1" className="form-label">Session Duration</label>
                                                <div className="row">
                                                    <div className="col">
                                                        <select className="form-select col-auto" aria-label="Hours">
                                                            <option selected>Hrs</option>
                                                            <option value="1">One</option>
                                                            <option value="2">Two</option>
                                                            <option value="3">Three</option>
                                                        </select>
                                                    </div>
                                                    <div className="col">
                                                        <select className="form-select col-auto" aria-label="Mins">
                                                            <option selected>Mins</option>
                                                            <option value="1">One</option>
                                                            <option value="2">Two</option>
                                                            <option value="3">Three</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-grid gap-2 ">
                                                <button type="button" className="btn btn-dark pt-2 pb-2">Start</button>
                                            </div>
                                        </form>
                                    </div> : <div className="createsession" style={{ paddingBottom: 90 }}>
                                        <form>
                                            <div className="mb-3">
                                                <label htmlFor="sessionurl" className="form-label">Url  </label>
                                                <input type="text" className="form-control" id="sessionurl" placeholder="url" />
                                            </div>

                                            <div className="d-grid gap-2 ">
                                                <button type="button" className="btn btn-dark pt-2 pb-2">Start</button>
                                            </div>
                                        </form>
                                    </div>} */}

                                <div className="createsession" style={{ paddingBottom: 90 }}>
                                    <form>
                                        <div className="d-grid gap-2 ">
                                            <p className="card-text loginbgtext">Create a new session or join an existing session and accelerate your uninterrupted & focussed
                                                productive hours.</p>
                                            <div className="row">
                                                <div className="col">
                                                    <select className="form-select col-auto" value={selectedhr} onChange={(e)=>selecthrchange(e)} aria-label="Hours">
                                                        <option selected>Hrs</option>
                                                        {generateOptionValue(hrs)}
                                                    </select>
                                                </div>
                                                <div className="col">
                                                    <select className="form-select col-auto" value={selectedmin} onChange={(e)=>selectminchange(e)} aria-label="Mins">
                                                        <option selected>Mins</option>
                                                        {generateOptionValue(mins)}
                                                    </select>
                                                </div>
                                            </div>
                                            <button type="button" onClick={() => joinsession()} className="btn btn-dark pt-2 pb-2">Join Session</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>)
}

export default Home;
