import React from "react";
import {
    BrowserRouter as Router,
    Routes,
    Route,
} from "react-router-dom";
import Login from "./login";
import Profile from "./profile";
import Sessionhistory from "./history";
import Home from "./home";
import Settings from "./settings";
import Activesession from "./activesession";
import Manageusers from "./manageusers";
import Components from "./components";
const Routermod = () => {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/home" element={<Home />} />
                <Route path="/activesession" element={<Activesession />} />
                <Route path="/components" element={<Components />} />
                <Route path="/login" element={<Login />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/sessionhistory" element={<Sessionhistory />} />
                <Route path="/settings" element={<Settings />} />
                <Route path="/musers" element={<Manageusers />} />
            </Routes>
        </Router>
    )
};
export default Routermod;