import React, {createContext, useState} from "react";

export const userdetails = createContext();

const UserdetailsProviders = (props) => {
    const [loggedinUser, setLoggedinUser] = useState({});
    const [ISLoading, setIsLoading] = useState(true);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [sessionDetails, setSessionDetails] = useState([]);
    const [sessionlist, setSessionlist] = useState([]);
    const [authToken, setAuthToken] = useState('');
    const [livesession, setLivesession] = useState([]);
    const [arenasummary, setArenasummary] = useState({});
    const [historydetails, sethistorydetails] = useState([]);
    const [whitelist, setWhitelist] = useState([]);
    const [blacklist, setBlacklist] = useState([]);
    return(
        <userdetails.Provider value={{
            loggedinUser,
            ISLoading,
            isAuthenticated,
            authToken,
            sessionDetails,
            sessionlist,
            setSessionlist,
            setSessionDetails,
            setAuthToken,
            setIsAuthenticated,
            setIsLoading,
            setLoggedinUser,
            livesession,
            setLivesession,
            arenasummary,
            setArenasummary,
            historydetails,
            sethistorydetails,
            whitelist,
            setWhitelist,
            blacklist,
            setBlacklist
        }}>
            {props.children}
        </userdetails.Provider>
    )
}
export default UserdetailsProviders;
