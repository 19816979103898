import {loginService, getSessionService, getArenaSummaryService} from "../api/services";
export const userModel = {
    state: {
        profile: {
            userdetails: {},
            localuser: {}
        },
        session: {},
        arenasummary: {},
        fetchError: undefined
    },
    reducers: {
        setLoginState(state, payload) {
            const profile = {...state.profile};
            profile.userdetails = payload;
            return {
                ...state,
                profile,
            };
        },
        setLocalLoginState(state, payload) {
            const profile = {...state.profile};
            profile.localuser = payload;
            return {
                ...state,
                profile,
            };
        },
        setLogout() {
            return {
                profile: {
                    userdetails: {},
                    localuser: {}
                },
                fetchError: undefined
            }
        },
        setSession(state, payload) {
            return {
                ...state,
                session: payload,
            };
        },
        setArenaSummary(state, payload) {
            return {
                ...state,
                arenasummary: payload,
            };
        },
    },
    effects: (dispatch) => ({
        async handlesetloggedinuser(payload, rootState) {
            await new Promise((resolve) => setTimeout(resolve, 1000));
            dispatch.userModel.setLoginState(payload);
        },
        async handleLogout() {
            dispatch.userModel.setLogout();
        },
        async handleLoggingin(payload, rootState) {
            const res = await loginService(payload);
            dispatch.userModel.setLocalLoginState(res);
        },
        async getSessionDetails(payload, rootState) {
            const res = await getSessionService(payload);
            dispatch.userModel.setSession(res);
        },
        async getArenaDetails(payload, rootState) {
            const res = await getArenaSummaryService(payload);
            dispatch.userModel.setArenaSummary(res);
        },
        
    }),
};