import axios from 'axios';
import urlConstants from './urls';

const errorcallback = (errorMessage, statusCode) => {
    return {
        errorCode: statusCode,
        errorMessage: errorMessage
    }
}

const successcallback = (res) => {
    if (res.status == 200) {
      return res.data;
    }
    return errorcallback("Please enter valid data", 504);
}

const apipostcall = (url, reqbody) => {
  return axios.post(url, reqbody)
  .then((res) => successcallback(res))
  .catch((errorMessage, statusCode) => errorcallback(errorMessage, statusCode));
}

const loginService = async (payload) => {
    const url = `${urlConstants.baseUrl}${urlConstants.login}`;
    return apipostcall(url, payload);
}
const getSessionService = async (payload) => {
    const url = `${urlConstants.baseUrl}${urlConstants.getSession}`;
    return apipostcall(url, payload);
}
const getArenaSummaryService = async (payload) => {
  const url = `${urlConstants.baseUrl}${urlConstants.getArenaSummary}`;
  return apipostcall(url, payload);
}
export {
  loginService,
  getSessionService,
  getArenaSummaryService
}