import React from "react";
import { useDispatch } from 'react-redux';
import logo from '../assets/logo_small.png';
import profileimg from '../assets/profileimg.png';
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import {getScreenWidthHeight} from "../utilities";

import './sidebar.css';
function Sidebar(props) {
    let navigate = useNavigate();
    const { logout } = useAuth0();
    /* const dispatch = useDispatch(); */
    function handleClick(route) {
        navigate(route);
    }
    const userdetails = props.userdetails;
    return (<>
        <div className="sidebar" style={{height: getScreenWidthHeight().height}}>
            <div className="logocontainer">
                <button onClick={() => handleClick("/home")}>
                    <img src={logo} alt="Logo" className="logo" />
                    <span>Productive Fish</span>
                </button>
            </div>
            <div className="profilecontainer">
                <div onClick={() => handleClick("/profile")} className={props.selected === "profile" ? "imagecontainer active" : "imagecontainer"}>
                    <span className="online"></span>
                    <img src={userdetails && userdetails.picture ? userdetails.picture : profileimg} alt="Logo" className="profileimg" />

                    <h6>Novice</h6>
                </div>
                <h4 onClick={() => handleClick("/profile")}>{userdetails && userdetails.name}</h4>
                <h5 onClick={() => handleClick("/profile")}>Average 89</h5>
            </div>
            <hr className="divider" />
            <ul className="linkcontainer">
                <li onClick={() => handleClick("/activesession")} className={props.selected === "asession" ? "active" : ""}><button type="button" className="btn btn-light btn-icon br-10"><i className="bi bi-lightning"></i></button> Active session</li>
                <li onClick={() => handleClick("/sessionhistory")} className={props.selected === "shistory" ? "active" : ""}><button type="button" className="btn btn-light btn-icon br-10"><i className="bi bi-clock"></i></button> Sessions history</li>
                <li onClick={() => handleClick("/settings")} className={props.selected === "settings" ? "active" : ""}><button type="button" className="btn btn-light btn-icon br-10"><i className="bi bi-gear"></i></button> Settings</li>
                <li onClick={() => handleClick("/musers")} className={props.selected === "muser" ? "active" : ""}><button type="button" className="btn btn-light btn-icon br-10"><i className="bi bi-gear"></i></button>Friends</li>
            </ul>
            <button onClick={() => {
                localStorage.removeItem("localactivesession");
                localStorage.removeItem("localuser");
                logout({ returnTo: "https://productivefish-82535.web.app/login" });
                // dispatch.userModel.handleLogout();
                }} type="button" className="btn btn-light logoutbutton"><i className="bi bi-gear"></i> Logout</button>
        </div>
    </>);
}
export default Sidebar;