import React, { useEffect, useContext, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import apiTasks from '../api';
import urlConstants from "../api/urls";
import Sidebar from "../components/sidebar";
import Header from "../components/header";
import Pagespinner from "../components/pagespinner";
import { userdetails } from "../context/userdetailsContext";
function Settings() {
    let navigate = useNavigate();
    const localusercheck = JSON.parse(localStorage.getItem('localuser'));
    if (!localusercheck || (localusercheck &&  (!localusercheck.isAuthenticated || !localusercheck.user))) {
        navigate("/login");
    }
    const [whitelistdomain, setWhitelistdomain] = useState("");
    const [blacklistdomain, setBlacklistdomain] = useState("");
    const {
        loggedinUser,
        setLivesession,
        ISLoading,
        setIsLoading,
        whitelist,
        setWhitelist,
        blacklist,
        setBlacklist
    } = useContext(userdetails);
    useEffect(() => {
        setIsLoading(false);
        const url = `${urlConstants.baseUrl}${urlConstants.generalGet}`;
        const payload = { "collection_name": "predetermined_domains", "filter": { "category": "p" }, "field_selection_filter": { "_id": 0, "focus_score": 0, "active_score": 0 }, "record_limit": "all", "return_type": 2, "ret_dict_key": "session_id" };
        apiTasks.post(url, payload).then(res => {
            let temparray = [];
            Object.keys(res).forEach(key => {
                temparray.push(res[key]);
            });
            setIsLoading(false);
            setWhitelist(temparray);
        }).catch(() => { });
        const url01 = `${urlConstants.baseUrl}${urlConstants.generalGet}`;
        const payload01 = { "collection_name": "predetermined_domains", "filter": { "category": "np" }, "field_selection_filter": { "_id": 0, "focus_score": 0, "active_score": 0 }, "record_limit": "all", "return_type": 2, "ret_dict_key": "session_id" };
        apiTasks.post(url01, payload01).then(res => {
            let temparray01 = [];
            Object.keys(res).forEach(key => {
                temparray01.push(res[key]);
            });
            setIsLoading(false);
            setBlacklist(temparray01);
        }).catch(() => { });
    }, []);

    const onchangeWhitelist = (e) => {
        setWhitelistdomain(e.target.value);
    }
    const onchangeBlacklist = (e) => {
        setBlacklistdomain(e.target.value);
    }
    const applywhitelist = () => {
        const payload = {
            "collection_name": "predetermined_domains",
            "filter": {},
            "obj": {
                "domain": whitelistdomain,
                "category": "p",
                "type": "na"
            }
        }
        const url = `${urlConstants.baseUrl}${urlConstants.generalPut}`;
        setIsLoading(true);
        apiTasks.post(url, payload).then(res => {
            setWhitelistdomain('');
            setIsLoading(false);
        }).catch(() => { });
    }
    const applyblacklist = () => {
        const payload = {
            "collection_name": "predetermined_domains",
            "filter": {},
            "obj": {
                "domain": blacklistdomain,
                "category": "np",
                "type": "na"
            }
        }
        const url = `${urlConstants.baseUrl}${urlConstants.generalPut}`;
        setIsLoading(true);
        apiTasks.post(url, payload).then(res => {
            setBlacklistdomain('');
            setIsLoading(false);
        }).catch(() => { });
    }
    
    return (<div className="mx-auto" style={{width: "1320px"}}>
        <div className="row">
            {ISLoading ?
                <Pagespinner /> : null}
            <div className="col col-3">
                <Sidebar selected="settings" userdetails={localusercheck.user} />
            </div>
            <div className="col col-9">
                <div className="rightcontainer">
                    <Header userdetails={localusercheck.user} />
                    <h2 className="page-title"><i className="bi bi-gear"></i> Settings</h2>
                    <div className="settingscontainer">
                        <h5 className="subtitle">Notification</h5>
                        <div className="card panelps">
                            <div className="card-body">
                                <div className="form-check form-switch">
                                    <input className="form-check-input" type="checkbox" role="switch" id="soundswitch" onChange={() => { }} /> <i className="bi bi-volume-up"></i>
                                    <label className="form-check-label" htmlFor="soundswitch">Sound</label>
                                </div></div>
                        </div>
                        <div className="card panelps">
                            <div className="card-body">
                                <div className="form-check form-switch">
                                    <input className="form-check-input" type="checkbox" role="switch" id="notificationswitch" onChange={() => { }} /> <i className="bi bi-bell"></i>
                                    <label className="form-check-label" htmlFor="notificationswitch">Push notification</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row" >
                        <div className="col" >

                            <div className="card panelps">
                                <div className="card-body">
                                    <div className="">
                                        <form className="row g-3">
                                            <div className="col-3 align-self-center">
                                                <label htmlFor="inputwhitelist" className="">Whitelist domain to add : </label>
                                            </div>
                                            <div className="col align-self-center">
                                                <input type="text" className="form-control" id="inputwhitelist" placeholder="Whitelist" value={whitelistdomain} onChange={(e) => onchangeWhitelist(e)} />
                                            </div>
                                            <div className="col-3 align-self-center">
                                                <button type="button" className="btn btn-primary" onClick={() => applywhitelist()}>Add Whitelist</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>

                            <h5 className="subtitle">Whitelisted Domains</h5>
                            <div style={{ maxHeight: "400px", overflow: "scroll", margin: "20px" }}>
                                <table className="table table-hover table-striped">
                                    <thead style={{ position: "sticky", backgroundColor: "#fff", top: "0px" }} >
                                        <tr>
                                            <th scope="col">S.No.</th>
                                            <th scope="col">Domain</th>
                                            <th scope="col">Category</th>
                                            <th scope="col">Type</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {whitelist && whitelist.length > 0 && whitelist.map((whitel, idx) =>
                                        (<tr key={idx}>
                                            <th scope="row">{idx+1}</th>
                                            <td>{whitel.domain}</td>
                                            <td>{whitel.category}</td>
                                            <td>{whitel.type}</td>
                                        </tr>)
                                        )}

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card panelps">
                    <div className="card-body">
                        <div className="">
                            <form className="row g-3">
                                <div className="col-3 align-self-center">
                                    <label htmlFor="inputblacklist" className="">Blacklist domain to add : </label>
                                </div>
                                <div className="col align-self-center">
                                    <input type="text" className="form-control" id="inputblacklist" placeholder="Blacklist" value={blacklistdomain} onChange={(e) => onchangeBlacklist(e)} />
                                </div>
                                <div className="col-3 align-self-center">
                                    <button type="button" className="btn btn-primary" onClick={() => applyblacklist()}>Add Blacklist</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <h5 className="subtitle">Blacklisted Domains</h5>
                        <div style={{ maxHeight: "400px", overflow: "scroll", margin: "20px" }}>
                            <table className="table table-hover table-striped" >
                                <thead style={{ position: "sticky", backgroundColor: "#fff", top: "0px" }}>
                                    <tr>
                                        <th scope="col">S.No.</th>
                                        <th scope="col">Domain</th>
                                        <th scope="col">Category</th>
                                        <th scope="col">Type</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {blacklist && blacklist.length > 0 && blacklist.map((whitel, idx) =>
                                    (<tr key={idx}>
                                        <th scope="row">{idx+1}</th>
                                        <td>{whitel.domain}</td>
                                        <td>{whitel.category}</td>
                                        <td>{whitel.type}</td>
                                    </tr>)
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>);
}
export default Settings;
