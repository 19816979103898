import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "../components/sidebar";
import Header from "../components/header";
import Leaderboard from "../components/leaderboard";
import VChart from "../components/vchart";
import Pagespinner from "../components/pagespinner";
import apiTasks from '../api';
import urlConstants from "../api/urls";
import { userdetails } from "../context/userdetailsContext";
import { startlocalagent } from "../utilities";
import DonutChart from "../components/donutchart";
import AreaChart from "../components/areachart";
import LineChart from "../components/linechart";
import ApexChart from "../components/heatmap";

let clearinterval01;
function Activesession() {
    let navigate = useNavigate();
    const {
        loggedinUser,
        isAuthenticated,
        sessionlist,
        ISLoading,
        setIsLoading,
        livesession,
        authToken,
        setArenasummary,
        setLivesession,
        arenasummary
    } = useContext(userdetails);

    const localusercheck = JSON.parse(localStorage.getItem('localuser'));
    if (!localusercheck || (localusercheck &&  (!localusercheck.isAuthenticated || !localusercheck.user))) {
        navigate("/login");
    }
    const prevsessionstoday = JSON.parse(localStorage.getItem("localprevsessions"));
    const localjoinsession = (ress) => {
        const localactivesession = JSON.parse(localStorage.getItem("localactivesession"));
        if (localactivesession && localactivesession && localactivesession.session_id) {
            const url = urlConstants.joinagent;
            const params = `session_id=${localactivesession.session_id}&arena_id=${localactivesession.arena_id}&user_id=${localusercheck.user.name}`;
            startlocalagent(url, params)
        }
    }
    const leavejoinsession = (ress) => {
        const localactivesession = JSON.parse(localStorage.getItem("localactivesession"));
        if (localactivesession && localactivesession && localactivesession.session_id) {
            const url = urlConstants.leaveagent;
            const params = `session_id=${localactivesession.session_id}&arena_id=${localactivesession.arena_id}&user_id=${localusercheck.user.name}`;
            startlocalagent(url, params);
        }
    }
    useEffect(() => {
        const localactivesession = JSON.parse(localStorage.getItem("localactivesession"));
        if (localactivesession) {
            localjoinsession(localactivesession);
        } else {
            navigate("/home");
        }
    }, [])

    useEffect(() => {
        const localactivesession = JSON.parse(localStorage.getItem("localactivesession"));
        if (localactivesession && localactivesession.session_id !== "0" && localactivesession.is_live) {
            clearinterval01 = setInterval(() => {
                const newpayload = { 
                    "arena_id": localactivesession.arena_id, 
                    "session_id": localactivesession.session_id, 
                    "chart_no_of_bars": 5 
                };
                const urlnew = `${urlConstants.baseUrl}${urlConstants.getArenaSummary}`;
                apiTasks.post(urlnew, newpayload).then(res => {
                    setIsLoading(false);
                    if (res && res.status !== "Session Expired") {
                        console.log(res);
                        setArenasummary(res);
                    } else {
                        clearInterval(clearinterval01);
                        localStorage.removeItem("localactivesession");
                        navigate("/home");
                    }
                });
            }, 30000);
        } else {
            clearInterval(clearinterval01); // Ensure interval is cleared if conditions are not met
        }
    
        return () => clearInterval(clearinterval01); // Clean up on unmount
    }, []);
        /* */ 
    const constructprevsession = (sessdetails) => {


    }
    const prevsessionClick = (prevsession) => {
        clearInterval(clearinterval01);
        const tmpprev = prevsession;
        tmpprev["session_focus_indicator_list"] = prevsession.focus_indicator;
        tmpprev["session_active_score_list"] = prevsession.score;
        setArenasummary(tmpprev);
    }
    const returntime = (dateval) => {
        return dateval.slice(10, 20);
    }
    const leavesession = () => {
        setIsLoading(true);
        const localactivesession = { ...JSON.parse(localStorage.getItem("localactivesession")) };
        const urlnew = `${urlConstants.baseUrl}${urlConstants.leaveSession}`;
        const payloadnew = { "session_id": localactivesession.session_id, "arena_id": localactivesession.arena_id, "pf_auth_token": authToken };
        clearInterval(clearinterval01);
        apiTasks.post(urlnew, payloadnew).then(res => {
            if (res && res.status === "success") {
                clearInterval(clearinterval01);
                apiTasks.post(`${urlConstants.baseUrl}${urlConstants.getSession}`, { "user_id": localusercheck.user.name }).then((sessionres) => {
                    if (sessionres && !sessionres.is_live) {
                        setLivesession(sessionres);
                        clearInterval(clearinterval01);
                        leavejoinsession(livesession);
                        localStorage.removeItem("localactivesession");
                        navigate("/home");
                    }
                    setIsLoading(false);
                });
            }
        });
    }
    return (<div className="mx-auto" style={{width: "1320px"}}><div className="row">
        {ISLoading ?
            <Pagespinner /> : null}
        <div className="col col-3">
            <Sidebar selected="asession" userdetails={localusercheck.user} />
        </div>
        <div className="col col-9" >
            <div className="rightcontainer">
                <Header userdetails={localusercheck.user} />
                <h2 className="page-title"><i className="bi bi-lightning"></i> Active Session</h2>
                <div className="btnbar">
                {prevsessionstoday && prevsessionstoday.length >0 && prevsessionstoday.map((val, idx) => ( <button type="button" key={idx} className="btn btn-secondary prevsession" onClick={()=>prevsessionClick(val)}> {val && val.start_time && returntime(val.start_time)}</button> ))}
                {arenasummary.is_live ? <button type="button" className="btn btn-primary prevsession">{arenasummary && arenasummary.start_time && returntime(arenasummary.start_time)}</button> : null}               
                {!arenasummary.is_live ? <button type="button" className="btn btn-secondary prevsession" onClick={()=>navigate("/home")} ><i className="bi bi-plus-lg"></i> New Session</button> : null}
                </div>
                
                <div className="row">
                    <div className="col col-8" style={{ paddingRight: 0 }}>
                        <div className="card panelps">
                            <div className="card-body" style={{ paddingBottom: '0' }}>
                                <h5 className="card-title">
                                    <div style={{ height: "25px" }}>
                                        <div className="float-start"><i className="bi bi-stars"></i> Your score {arenasummary.is_live ? <span className="badge bg-light live">Live</span> : null}</div>
                                        <div className="float-end">{arenasummary.is_live ? <button type="button" className="btn btn-dark" style={{ margin: 0, fontSize: '12px', padding: "2px 10px", minWidth: 'auto', verticalAlign: 'middle' }} onClick={() => leavesession()}>Leave session</button>: null}</div>
                                    </div>
                                </h5>
                                <div className="row">
                                    <div className="col">
                                        <div className="scoresummary">
                                            <h3>{arenasummary.is_live ? arenasummary.score : arenasummary.final_score}</h3>
                                            <h5>Score</h5>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <DonutChart
                                            percent={arenasummary.percent_complete}
                                            target_duration={arenasummary.target_duration}
                                            current_duration={arenasummary.current_duration}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card panelps">
                            <div className="card-body">
                            <h5 className="card-title"><i className="bi bi-vinyl"></i> Focus Score </h5>
                        <ApexChart score={arenasummary.session_focus_indicator_list} />
                        </div>
                        <div className="card-footer text-left">
                                <span className="card-footer-text">Productive: <b>{arenasummary.total_productive_mins} </b>hrs</span> <span className="card-footer-text">Distraction: <b>{arenasummary.total_unproductive_mins} </b>hrs</span> <span className="card-footer-text">Longest streak: <b>{arenasummary.longest_streak}</b></span>

                            </div>
                        </div>
                        {/* <div className="card panelps">
                            <div className="card-body">
                                <h5 className="card-title"><i className="bi bi-vinyl"></i> Focus Score </h5>
                                <LineChart score={arenasummary.session_focus_indicator_list} />

                            </div>
                            <div className="card-footer text-left">
                                <span className="card-footer-text">Productive: <b>{arenasummary.total_productive_mins} </b>hrs</span> <span className="card-footer-text">Distraction: <b>{arenasummary.total_unproductive_mins} </b>hrs</span> <span className="card-footer-text">Longest streak: <b>{arenasummary.longest_streak}</b></span>

                            </div>
                        </div> */}
                        <div className="card panelps">
                            <div className="card-body">
                                <h5 className="card-title"><i className="bi bi-check-circle"></i> Active score</h5>
                                <AreaChart score={arenasummary.session_active_score_list} />
                            </div>
                            <div className="card-footer text-left">
                                <span className="card-footer-text">Productive: <b>{arenasummary.total_productive_mins} </b>hrs</span> <span className="card-footer-text">Distraction: <b>{arenasummary.total_unproductive_mins} </b>hrs</span> <span className="card-footer-text">Average Score: <b>{arenasummary.session_avg_active_score}</b></span>
                            </div>
                        </div>
                    </div>
                    <div className="col col-4" style={{ paddingLeft: 0, paddingRight: 0 }}>
                        <div className="card panelps">
                            <div className="card-body">
                                <h5 className="card-title"><div style={{ height: "25px" }}>
                                    <div className="float-start"><i className="bi bi-trophy"></i> Leaderboard</div>
                                    <div className="float-end"><button onClick={()=> {  navigate('/musers');}} type="button" style={{ margin: 0, padding: "2px 10px", minWidth: 'auto', fontSize: '12px', verticalAlign: 'middle' }} className="btn btn-outline-dark">Invite</button> </div>
                                </div> </h5>
                                <Leaderboard leaderboard={arenasummary.leaderboard} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>)
}
export default Activesession;