import React from "react";

import Notification from "./components/notification";
import Leaderboard from "./components/leaderboard";
import Pagespinner from "./components/pagespinner";
import Pagination from 'react-bootstrap/Pagination'
import logo from './assets/logo.png';
import profileimg from './assets/profileimg.png';
import profileimg_big from './assets/profileimg_big.png';
import loginbgimg from './assets/loginbg.png';
import smallprofile from './assets/smallimage.png';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import faker from 'faker';
function Components() {


    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        Legend
    );

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: false,
                text: 'Chart.js Bar Chart',
            },
        },
    };

    const data = {
        labels: ['15', '30', '45', '60', '90', '105', '120'],
        datasets: [
            {
                label: 'Low',
                data: ['15', '30', '45', '60', '90', '105', '120'].map(() => faker.datatype.number({ min: 0, max: 100 })),
                backgroundColor: 'rgba(237, 132, 143, 1)',
            },
            {
                label: 'High',
                data: ['15', '30', '45', '60', '90', '105', '120'].map(() => faker.datatype.number({ min: 0, max: 100 })),
                backgroundColor: 'rgba(70, 46, 223, 1)',
            },
        ],
    };
    const friendslist = [{
        userName: "Narek yeva",
        userStatus: "online",
        profileimg: smallprofile,
        scoreStatus: "up",
        userType: "Novice",
        score: 98,
        delta: "0.2"
    }, {
        userName: "Bagrat Lucineh",
        userStatus: "offline",
        scoreStatus: "down",
        profileimg: null,
        userType: "Beginner",
        score: 65,
        delta: "0.2"
    }, {
        userName: "Arman Maral",
        userStatus: "online",
        scoreStatus: "down",
        userType: "Advanced",
        score: 23,
        delta: "0.2"
    }, {
        userName: "Gevorg Garegin",
        userStatus: "online",
        scoreStatus: "down",
        profileimg: smallprofile,
        userType: "Beginner",
        score: -54,
        delta: "0.2"
    }];
    return (<>
        <h2 className="page-title"><i className="bi bi-lightning"></i> Active Sessions</h2>
        <h2 className="page-title"><i className="bi bi-clock"></i> Sessions history</h2>
        <h2 className="page-title"><i className="bi bi-gear"></i> Settings</h2>
        <h2 className="page-title">Profile settings</h2>
        <button type="button" className="btn btn-primary" style={{ minWidth: 'none'}}>Session 1</button>
        <button type="button" className="btn btn-secondary"><i className="bi bi-plus-lg"></i> New Session</button>
        <button type="button" className="btn btn-light"><i className="bi bi-gear"></i> Logout</button>
        <button type="button" className="btn btn-dark">Leave session</button>
        <button type="button" className="btn btn-outline-dark">Invite</button>
        <button type="button" className="btn btn-dark">Save</button>
        <button type="button" className="btn btn-outline-dark">Cancel</button>
        <ul className="linkcontainer">
            <li ><button type="button" className="btn btn-light btn-icon"><i className="bi bi-lightning"></i></button> Active sessions</li>
            <li className="active"><button type="button" className="btn btn-light btn-icon"><i className="bi bi-clock"></i></button> Sessions history</li>
            <li><button type="button" className="btn btn-light btn-icon"><i className="bi bi-gear"></i></button> Settings</li>
        </ul>
        <div className="logocontainer">
            <img src={logo} alt="Logo" className="logo" />
            <span>Productive Fish</span>
        </div>
        <div className="welcometext">☀️  Welcome back Gevorg!</div>
        <div>
            <Notification />
        </div>
        <Pagespinner />
        <div className="profilecontainer">
            <div className="imagecontainer">
                <span className="online"></span>
                <img src={profileimg} alt="Logo" className="profileimg"></img>
                <h6>Novice</h6>
            </div>
            <h4>Gevorg Garegin</h4>
            <h5>Average 89</h5>
        </div>
        <div className="card panelps">
            <div className="card-body">
                <h5 className="card-title"><i className="bi bi-stars"></i> Your score</h5>
                <div className="row">
                    <div className="col">
                        <div className="scoresummary">
                            <h3>48</h3>
                            <h5>Score</h5>
                        </div>
                    </div>
                    <div className="col">
                        <div className="profilescode">
                            <h3>89</h3>
                            <h5>Average Score</h5>
                        </div>
                        <div className="profileimagecontainer">
                            <img src={profileimg_big} alt="Logo" className="profileimg"></img>
                            <h6>Novice</h6>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <div className="card panelps">
            <div className="card-body">
                <h5 className="card-title"><i className="bi bi-vinyl"></i> Focus Area</h5>
                <Bar options={options} data={data} />
            </div>
            <div className="card-footer text-left">
                <span className="card-footer-text">Productive: <b>40 minutes</b></span> <span className="card-footer-text">Distraction: <b>40 minutes</b></span>

            </div>
        </div>
        <div className="card panelps">
            <div className="card-body">
                <h5 className="card-title"><i className="bi bi-vinyl"></i> Focus Area</h5>

            </div>
            <div className="card-footer text-left">
                <span className="card-footer-text">Productive: <b>40 minutes</b></span> <span className="card-footer-text">Distraction: <b>40 minutes</b></span>

            </div>
        </div>
        <div className="card panelps">
            <div className="card-body">
                <h5 className="card-title"><i className="bi bi-check-circle"></i> Active score</h5>
                <p ><span className="datetimelabel">10:AM  |  Mon, 27 Sep 2021</span> <span className="status">
                    <span className="badge bg-light live">Live</span>
                </span></p>
            </div>
            <div className="card-footer text-left">
                <span className="card-footer-text">Productive: <b>40 minutes</b></span> <span className="card-footer-text">Distraction: <b>40 minutes</b></span>
            </div>
        </div>
        <div className="card panelps">
            <div className="card-body">
                <h5 className="card-title"><i className="bi bi-trophy"></i> Leaderboard</h5>

                <div className="status">
                    <span className="badge bg-light live">Live</span>
                </div>

                <div className="status">
                    <span className="badge bg-light left">Left</span>
                </div>

                <div className="status">
                    <span className="badge bg-light up"><i className="bi bi-triangle-fill"></i> 3.1</span>
                </div>
                <div className="status">
                    <span className="badge bg-light down"><i className="bi bi-triangle-fill"></i> 3.1</span>
                </div>
            </div>
        </div>
        <div className="card panelps">
            <div className="row">
                <div className="col loginbgimgcontainer">
                    <div className="loginbgimgholder">
                        <img src={loginbgimg} alt="background" width="275" height="275" />
                        <p className="card-text loginbgtext">Create a new session or join an existing session and accelerate your uninterrupted & focussed
                            productive hours.</p>
                    </div>
                </div>
                <div className="col"></div>
            </div>
        </div>
        <div className="card panelps">
            <div className="card-body">
                <div className="mb-3">
                    <label htmlFor="exampleFormControlInput2" className="form-label">Name</label>
                    <input type="text" className="form-control" id="exampleFormControlInput2" placeholder="Gevorg Garegin" />
                </div>
                <div className="mb-3">
                    <label htmlFor="exampleFormControlInput1" className="form-label">Email ID</label>
                    <input type="email" className="form-control" id="exampleFormControlInput1" placeholder="Gevorg.garegin@sample.com" />
                </div>
            </div>
        </div>
        <h5 className="subtitle">Notification</h5>
        <div className="card panelps">
            <div className="card-body">
                <div className="form-check form-switch">
                    <input className="form-check-input" type="checkbox" role="switch" id="soundswitch" onChange={() => { }} /> <i className="bi bi-volume-up"></i>
                    <label className="form-check-label" htmlFor="soundswitch">Sound</label>
                </div></div>
        </div>
        <div className="card panelps">
            <div className="card-body">
                <div className="form-check form-switch">
                    <input className="form-check-input" type="checkbox" role="switch" id="notificationswitch" onChange={() => { }} checked /> <i className="bi bi-bell"></i>
                    <label className="form-check-label" htmlFor="notificationswitch">Push notification</label>
                </div>
            </div>
        </div>
        <Pagination className="gridpagination">
            <Pagination.First />
            <Pagination.Prev />
            <Pagination.Item>{1}</Pagination.Item>
            <Pagination.Ellipsis />

            <Pagination.Item>{10}</Pagination.Item>
            <Pagination.Item>{11}</Pagination.Item>
            <Pagination.Item active>{12}</Pagination.Item>
        </Pagination>
        <div className="card panelps">
            <div className="card-body">
                <h5 className="card-title"><i className="bi bi-trophy"></i> Leaderboard</h5>
                <Leaderboard friendslist={friendslist} />
            </div>
        </div>
    </>)
}
export default Components;