import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import Sidebar from "../components/sidebar";
import Header from "../components/header";
import Pagespinner from "../components/pagespinner";
import profileimg_big from '../assets/profileimg_big.png';
function Profile() {
    const [profilephoto, setProfilephoto] = useState(false);
    const { user, isAuthenticated, isLoading } = useAuth0();
    const [uname, setuname] = useState("");
    const [uemail, setuemail] = useState("");
    
    let navigate = useNavigate();
    const localusercheck = JSON.parse(localStorage.getItem('localuser'));
    if (!localusercheck || (localusercheck &&  (!localusercheck.isAuthenticated || !localusercheck.user))) {
       navigate("/login");
    }
    useState(() => {
        if (localusercheck.user && localusercheck.user.nickname && localusercheck.user.email) {
            setuname(localusercheck.user.nickname);
            setuemail(localusercheck.user.email);
        }
    }, [user])
    return (<div className="mx-auto" style={{width: "1320px"}}>
        <div className="row">
        {isLoading ?
                <Pagespinner /> : null}
            <div className="col col-3">
                <Sidebar selected="profile" userdetails={localusercheck.user}  />
            </div>
            <div className="col col-9">
                <div className="rightcontainer">
                    <Header userdetails={localusercheck.user} />
                    <h2 className="page-title">Profile Settings</h2>
                    <div className="scorecontainer row">
                        <div className="col">
                            <div className="profileimagecontainer" onMouseEnter={() => setProfilephoto(true)} onMouseLeave={() => setProfilephoto(false)}>
                                <img src={localusercheck.user && localusercheck.user.picture ? localusercheck.user.picture : profileimg_big} alt="Logo" className="profileimg"></img>
                                <h6>Novice</h6>
                                {profilephoto ? <div className="profileoverlay">
                                    <button className="btn btn-light"><i className="bi bi-pencil"></i></button>
                                </div> : null}
                            </div>
                            <div className="profilescode">
                                <h3>89</h3>
                                <h5>Average Score</h5>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col col-6">
                            <div className="mb-3">
                                <label htmlFor="exampleFormControlInput2" className="form-label">Name</label>
                                <input type="text" className="form-control" id="exampleFormControlInput2" value={uname} placeholder="Gevorg Garegin" />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="exampleFormControlInput1" className="form-label">Email ID</label>
                                <input type="email" className="form-control" id="exampleFormControlInput1" value={uemail} placeholder="Gevorg.garegin@sample.com" />
                            </div>
                        </div>
                    </div>
                    <div className="buttonbar">
                        <button type="button" className="btn btn-dark">Save</button>
                        <button type="button" className="btn btn-outline-dark">Cancel</button>
                    </div>
                </div>
            </div>
        </div>

    </div>);
}
export default Profile;