import React, { Component } from 'react';
import ReactApexChart from 'react-apexcharts';

function ApexChart(props) {
    
    let scorelocal = props.score;
    const scorelocal01 = [];
    const scorelocal02 = [];
    const scorelocal03 = [];
    const scorelocal04 = [];
    const scorelocal05 = [];
    const scorelocal06 = [];
    if (scorelocal) {
        const totalscore = scorelocal && scorelocal.length;
        const newarrlength =  Math.trunc(totalscore/6);
        for (var i=0; i < newarrlength; i++) {
            const val = i*6;
            scorelocal01.push(scorelocal[val+1]);
            scorelocal02.push(scorelocal[val+2]);
            scorelocal03.push(scorelocal[val+3]);
            scorelocal04.push(scorelocal[val+4]);
            scorelocal05.push(scorelocal[val+5]?scorelocal[val+5]:0);
            scorelocal06.push(scorelocal[val+6]?scorelocal[val+6]:1);
        }
    }
    
    const areaoptions = {
        series: [{
          name: '1',
          data: scorelocal01
        },
        {
          name: '2',
          data: scorelocal02
        },
        {
          name: '3',
          data: scorelocal03
        },
        {
          name: '4',
          data: scorelocal04
        },
        {
          name: '5',
          data: scorelocal05
        },
        {
          name: '6',
          data: scorelocal06
        }],

        options: {
          chart: {
            height: 350,
            type: 'heatmap',
            toolbar: {
                show: false
              }
          },
          legend: {
            show: false,
          },
          plotOptions: {
            heatmap: {
              colorScale: {
                ranges: [{
                    from: 0,
                    to: 0,
                    color: '#00b9fb'
                  },
                  {
                    from: 1,
                    to: 1,
                    color: '#008FFB'
                  },
                ],
              },
          
            }
          },
          xaxis: {
            type: 'category',
            categories: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25]
          },
          dataLabels: {
            enabled: false
          },
          colors: ["#008FFB"],
          title: {
            text: ''
          },
        },
    };
    return (<div id="chart" style={{textAlign: "center"}}>{scorelocal && scorelocal.length > 5 ? (<ReactApexChart options={areaoptions.options} series={areaoptions.series} type="heatmap" height={200} />) : "No records found"}</div>);
}

export default ApexChart;