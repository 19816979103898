import React from "react";
function Multiuser() {
    return (<div className="multiimage">
        <div className="griduser">A</div>
        <div className="griduser">B</div>
        <div className="griduser">C</div>
        <div className="griduser">D</div>
        +4
    </div>);
}
export default Multiuser;
